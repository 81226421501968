import { useAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";
import {IonButton, useIonRouter} from "@ionic/react";
import { callbackUri } from "../auth.config";
import useTranslator from "./useTranslator";

const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();
  const translator = useTranslator();
  const navigation = useIonRouter();
  const doLogout = async () => {
    await logout({
      async openUrl(url) {
        await Browser.open({
          url,
          windowName: "_self",
        });
      },
      logoutParams: {
        returnTo: callbackUri
      }
    });
    navigation.push('/app/home', 'root', 'replace');
  };

  return <IonButton onClick={doLogout}>{translator.translate('component.logoutButton.title')}</IonButton>;
};

export default LogoutButton;
