import React from "react";
import {IonLoading} from "@ionic/react";
import useTranslator from "./useTranslator";

const Loader: React.FC = () => {
  const translator = useTranslator();
  let str = translator.translate("component.loader.message");
  if (str === "component.loader.message" )
    str = "Loading...";
  return <IonLoading spinner="circles" message={str} isOpen={true} duration={2000} />;
};

export default Loader;
