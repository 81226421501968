import {
    IonCard, IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonPage,
} from "@ionic/react";

import { useAuth0 } from "@auth0/auth0-react";
import { AppHeader } from "../components/AppHeader";
import React from "react";
import Loader from "../components/Loader";
import useTranslator from "../components/useTranslator";
import SideBar from "../components/SideBar";

const Download: React.FC = () => {
  const { isLoading } = useAuth0();
  const translator = useTranslator();

  if (isLoading) {
    return <Loader/>;
  }

  return (
      <>
          <SideBar/>
          <IonPage id="main-content">
        <AppHeader title={"Contact"}/>
        <IonContent fullscreen>
            <div style={{padding: "1rem"}}>
                <h1>{translator.translate('download.headline')}</h1>
                <p>{translator.translate('download.description')}</p>
            </div>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>{translator.translate('download.FollowMeHeadline')}</IonCardTitle>
                    <IonCardSubtitle>{translator.translate('download.FollowMeDescription')}</IonCardSubtitle>
                    <a href="./download/FollowMe.zip" target="_blank">FollowMe</a>
                </IonCardHeader>
            </IonCard>

            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>{translator.translate('download.MetatraderPanelHeadline')}</IonCardTitle>
                    <IonCardSubtitle>{translator.translate('download.MetatraderPanelDescription')}</IonCardSubtitle>
                    <a href="./download/MetaTrader-Panel.zip" target="_blank">MetaTrader-Panel</a>
                </IonCardHeader>
            </IonCard>

        </IonContent>
      </IonPage>
          </>
  );
};

export default Download;
