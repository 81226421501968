import { useTranslation } from 'react-i18next';

const useTranslator = () => {
    const { t, i18n } = useTranslation();

    const translate = (key: string, options?: any): string => {
        // @ts-expect-error
        return t(key, options);
    };

    return {
        t: translate,
        translate,
        i18n,
    };
};

export default useTranslator;

// todo: find the correct way to get this type
export type TFun = (key: string, options?: {}) => string; // i18n translate function
