import { isPlatform } from "@ionic/react";

//local
//export const domain = "follow-me.eu.auth0.com";
//export const clientId = "CWXFzJR5Ea1MvO0eeoFMdV5N6SRjmp4x";

//prod
export const domain = "followme-client.eu.auth0.com";
export const clientId = "Diu1IA448edofbIPDRThDB0pF8NcylKs";
export const audience = "https://client.metatrader-panel.com/api/v1/";

const appId = "com.followme.app";

// Use `auth0Domain` in string interpolation below so that it doesn't
// get replaced by the quickstart auto-packager
const auth0Domain = domain;
const iosOrAndroid = isPlatform('hybrid');

export const callbackUri = iosOrAndroid
  ? `${appId}://${auth0Domain}/capacitor/${appId}/callback`
    : process.env.REACT_APP_API_ENDPOINT;