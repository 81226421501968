// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCM_ItEu2QVSRdulE4vLl9o1RuhipbvHUs",
  authDomain: "followme-project-id.firebaseapp.com",
  projectId: "followme-project-id",
  storageBucket: "followme-project-id.appspot.com",
  messagingSenderId: "105676739890",
  appId: "1:105676739890:web:4c3d2cf56354277532332b",
  measurementId: "G-NN6L156YSZ"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const messaging = getMessaging(firebaseApp);

//@ts-ignore
export const getMyToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BJXNRafl9EdVE9k9e1o2ZMWMFfnsqBhWqW96Olz2OTL8siuQvwrHiuEkarPo5THNvJCjkCQsWitBDGjIKTHW2ys'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}



export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
});