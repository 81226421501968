import {
    IonAccordion,
    IonAccordionGroup,
    IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonCol,
    IonContent, IonDatetime, IonGrid, IonIcon, IonItem, IonLabel, IonModal,
    IonPage, IonRow, IonSegment, IonSegmentButton, useIonModal, IonCardHeader, IonDatetimeButton
} from "@ionic/react";

import { useAuth0 } from "@auth0/auth0-react";
import {AppHeader} from "../components/AppHeader";
import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {getTradeByIdAndType, Utils} from "../components/Utils";
import useTranslator from "../components/useTranslator";
import i18n from "../i18n";
import {arrowDown, arrowUp} from "ionicons/icons";
import TradeDetails from "../components/TradeDetails";
import "./Portfolio.scss";
import Loader from "../components/Loader";
import {GlobalStateContext, DispatchStateContext} from "../App";
import {Doughnut} from "react-chartjs-2";
import {ChartOptions} from "chart.js";
import SideBar from "../components/SideBar";


const Portfolio: React.FC = () => {
    const translator = useTranslator();
    const [view, setView] = useState('open');
    const [open, setOpen] = useState<object[]>([]);
    const [closed, setClosed] = useState<object[]>([]);
    const [closedFlat, setClosedFlat] = useState<object[]>([]);
    const [openSignals, setOpenSignals] = useState<object[]>([]);
    const [closeSignals, setCloseSignals] = useState<object[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [trade, setTrade] = useState({});
    const [statistics, setStatistics] = useState({hitRatio:0, plRatio:0, totalTrades:0, totalReturn:0, totalContracts:0, profitTrades:0,lossTrades:0, bestTrade:0,worstTrade:0,sumProfit:0,sumLoss:0});
    const [closedDate,setClosedDate] = useState("");
    const humanizeDuration = require("humanize-duration");
    const [selectedAccount, setSelectedAccount] = useState([]);

    const useGlobalState = () => [
        React.useContext(GlobalStateContext),
        React.useContext(DispatchStateContext)
    ];

    const [state, dispatch] = useGlobalState();


    if(state) {
        if ("accountNumber" in state) {
            let account = "";
            account = state?.accountNumber;
            if (account.toString() !== selectedAccount.toString()) {
                setSelectedAccount(account as never);
            }
        }
    }

    const handleDismiss = () => {
        dismiss();
    };

    const [present, dismiss] = useIonModal(TradeDetails, {
        onDismiss: handleDismiss,
        trade: trade,
        view: view,
        closed: closedFlat,
        openSignals: openSignals,
        closeSignals: closeSignals
    });

    useEffect(()=>{
        getTradeHistory("/trades/" + selectedAccount);
        getStats("/statistics/" + selectedAccount);
    }, [selectedAccount]);

    const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

    let openTabs: (string)[];

    const toggleAccordion = (values:any) => {
        if (!accordionGroup.current) {
            return;
        }
        const nativeEl = accordionGroup.current;
        setTimeout(()=>{nativeEl.value = values}, 0);
    };

    useLayoutEffect(() => {
        if (!accordionGroup.current) {
            return;
        }

        openTabs = [];

        if(view === 'open') {
            accordionsOpen.forEach(item => {
                openTabs.push(item.key);
            });

        }
        if(view === 'closed') {
            accordionsClose.forEach(item => {
                openTabs.push(item.key);
            })
        }
        if(view === 'open' || view === 'closed') {
            //@ts-ignore
            //document.querySelector("ion-accordion-group").value = openTabs;

            toggleAccordion(openTabs);

            //accordion-animated accordion-expanded
            //document.querySelector()
            //accordionGroup.current.value = openTabs;
            //accordionGroup.current.value = ['[DAX40]', '[DJI30]'];

            //const tabber = document.querySelectorAll('.accordion-animated button');
            //@ts-ignore
            /*
            for (const tab of tabber) {
                //tab.classList.add('accordion-expanded');
                tab.click();
            }
            */
        }

        //accordionGroup.current.value = openTabs;

    }, [open, closed, view, closedDate]);


    const getTradeHistory = async (url:string) => {
        Utils.accessToken = await getAccessTokenSilently();
        const res = await Utils.readApi(url);
        //@ts-ignore
        const responseData = res.responseData;
        console.log("ResponseData: ", responseData);
        preparePositions(responseData);
    }

    const getStats = async (url: string) => {
        Utils.accessToken = await getAccessTokenSilently();
        const res = await Utils.readApi(url);
        console.log("Stats: ", res.responseData);
        //@ts-ignore
        setStatistics(res.responseData);
        //preparePositions(responseData);
    }

    const getOpen = (obj:object) => {
        const filtered = Object.values(obj).filter(ob => ob.length === 1);
        return filtered;
    }

    const getClosed = (obj:object) => {
        const filtered = Object.values(obj).filter(ob => ob.length === 2);
        return filtered;
    }

    const preparePositions = (responseData:Array<object>) => {
        const prepare = {};
        for (const item of responseData ) {
            if(item.hasOwnProperty('reference')) {
                //@ts-ignore
                if (!prepare.hasOwnProperty(item.reference))
                    //@ts-ignore
                    prepare[item.reference] = [];
                //@ts-ignore
                prepare[item.reference].push(item);
            }
        }

        const OpenSignals : any[] = [];
        const CloseSignals : any[] = [];
        // Open Positions

        const openPositions : any[] = [];
        for (const item of getOpen(prepare)) {

            if(item[0].hasOwnProperty('instrument')) {
                if (!openPositions.hasOwnProperty(item[0].instrument))
                    //@ts-ignore
                    openPositions[item[0].instrument] = [];
                openPositions[item[0].instrument].push(item[0]);
                OpenSignals[item[0].followerPositionId] = item[0];
            }
        }

        const closedPositions : any[] = [];
        const closedPosFlat = getClosed(prepare);
        setClosedFlat(closedPosFlat);
        for (const item of closedPosFlat) {
            // get dates
            let dateStart, dateEnd;

            if(item[0].hasOwnProperty('timestamp')) {
                dateStart = item[0].timestamp.split("T")[0];
                if (!closedPositions.hasOwnProperty(dateStart)) {
                    //@ts-ignore
                    closedPositions[dateStart] = [];
                }
            }

           if(item[1].hasOwnProperty('timestamp')) {
               dateEnd = item[1].timestamp.split("T")[0];
               if (!closedPositions.hasOwnProperty(dateEnd)) {
                   //@ts-ignore
                   closedPositions[dateEnd] = [];
               }
           }

            if(item[0].hasOwnProperty('followerDealType')) {
                if (item[0].followerDealType === "Close") {
                    CloseSignals[item[0].followerPositionId.toString()] = item[0];
                }
            }
            if(item[1].hasOwnProperty('followerDealType')) {
                if (item[1].followerDealType === "Close") {
                    CloseSignals[item[1].followerPositionId.toString()] = item[1];
                }
            }


           // sort the trades
           if(item[0].hasOwnProperty('instrument')) {
                   if (!closedPositions[dateStart].hasOwnProperty(item[0].instrument))
                       //@ts-ignore
                       closedPositions[dateStart][item[0].instrument] = [];
                   closedPositions[dateStart][item[0].instrument].push(item[0]);
           }

           if(item[1].hasOwnProperty('instrument')) {
               if (!closedPositions[dateEnd].hasOwnProperty(item[1].instrument))
                   //@ts-ignore
                   closedPositions[dateEnd][item[1].instrument] = [];
               closedPositions[dateEnd][item[1].instrument].push(item[1]);
           }


        }

        setOpen(openPositions);
        setClosedDate(Object.keys(closedPositions).sort()[Object.keys(closedPositions).length-1]);
        setOpenSignals(OpenSignals);
        setCloseSignals(CloseSignals);

        //sort and set closed
        interface Trade extends Object {
            id: string;
        }
        for (let trades in closedPositions) {
            //@ts-ignore
            closedPositions[trades] = closedPositions[trades].sort((a: Trade[] = [],b: Trade[] = []) => { return a.id - b.id});
        }
        setClosed(closedPositions);
    }

  const getOpenPosTimeById = (id:"") => {
      const openTransaction = getTradeByIdAndType(closedFlat, id, 'Open') || {};
      return openTransaction.followerUnixTs || 0;
  }

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

      if (isLoading) {
        return <Loader/>;
      }

    if (!isAuthenticated) return null;

    const adjustView = (view:string) => {
        setView(view);
    }

    const accordionsOpen: any[] = [];
    const accordionsClose: any[] = [];

    const buildAccordions = (source: any[] = [], destination: any[] = []) => {
        for (const instrument in source) {
            const plainInstrument = instrument.replace("[","").replace("]","");
            let currency = translator.translate("instruments." + plainInstrument + ".currencySign") !== "instruments." + plainInstrument + ".currencySign" ? translator.translate("instruments." + plainInstrument + ".currencySign") : "";

            // prepare trades to display
            const tradeRows: any[] = [];
            const visibleTrades: any[] = [];

            const DateOptions = { month: 'numeric', day: 'numeric' };
            for (let i = 0; i < source[instrument].length; i++){
                let followerUnixTS_start = 0;
                let humanizedTime = "-";
                if (view ==='closed') {
                    followerUnixTS_start = getOpenPosTimeById(source[instrument][i].followerPositionId);
                    humanizedTime = humanizeDuration(Math.round((source[instrument][i].followerUnixTs - followerUnixTS_start)/1000) * 1000, { language: i18n.language }).split(",")[0];
                }

                if (((view === 'open') && source[instrument][i].followerDealType !== 'Close') || ((view ==='closed') && source[instrument][i].followerDealType === 'Close')) {
                    tradeRows.push(<IonRow className={"data-row"} key={source[instrument][i].followerPositionId} onClick={() => {
                        setTrade(source[instrument][i]);
                        present({
                            cssClass: 'trade-details-modal',
                        });
                    }}>
                        {   //@ts-ignore
                            (view === 'open') ? <IonCol>{new Date(source[instrument][i].timestamp).toLocaleDateString(i18n.language, DateOptions)}</IonCol> :
                        <IonCol>{humanizedTime}</IonCol>}

                        <IonCol>{source[instrument][i].followerTradeDirection === "Sell" ? <IonIcon style={{color:'red'}} icon={arrowDown} /> : <IonIcon style={{color:'lightgreen'}} icon={arrowUp} />} {source[instrument][i].followerLotSize} {translator.translate("portfolio.lots")}</IonCol>
                        {view === 'open' && <IonCol>{currency} {(Math.round(parseFloat(source[instrument][i].followerPrice)  * 100)/100).toFixed(2)}</IonCol>}
                        {view === 'closed' && <IonCol className={((closeSignals?.[source[instrument][i].followerPositionId]?.['followerTradeResult']  || 0) >= 0) ? 'positive' : 'negative'}>{(Math.round(parseFloat(closeSignals?.[source[instrument][i].followerPositionId]?.['followerTradeResult']  || 0)*100)/100).toFixed(2)} €</IonCol>}
                    </IonRow>);
                    visibleTrades.push(source[instrument][i]);
                }
            }

            tradeRows.sort((a,b) => a.timestamp - b.timestamp);

            destination.push(<IonAccordion value={instrument} key={instrument}>
                <IonItem slot="header" color="light">
                    <IonLabel>{instrument} ({tradeRows.length}) {view === "closed" &&
                      <span> - {((Math.round(visibleTrades.reduce((n, {followerTradeResult}) => n + followerTradeResult, 0)*100)/100) >= 0) ? translator.translate("portfolio.profit") :  translator.translate("portfolio.loss")}:
                        <span className={(Math.round(visibleTrades.reduce((n, {followerTradeResult}) => n + followerTradeResult, 0)*100)/100) >= 0 ? 'positive' : 'negative'}> {(Math.round(visibleTrades.reduce((n, {followerTradeResult}) => n + followerTradeResult, 0)*100)/100).toFixed(2)} €</span>
                      </span>}
                    </IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                    <IonGrid>
                        <IonRow className={'table_head'}>
                            <IonCol style={{textAlign:'right'}}>{view === 'open' ? translator.translate("portfolio.date") : translator.translate("portfolio.holdingPeriod")}</IonCol>
                            <IonCol style={{textAlign:'right'}}>{translator.translate("portfolio.direction")}</IonCol>
                            <IonCol style={{textAlign:'right'}}>{view === 'open' ? translator.translate("portfolio.costs") : translator.translate("portfolio.profitLoss")}</IonCol>
                        </IonRow>
                        {tradeRows}
                    </IonGrid>
                </div>
            </IonAccordion>);
        }
    }

    if (view === "open")
        buildAccordions(open,accordionsOpen);
    else
        buildAccordions(closed[closedDate],accordionsClose);

    const dateChanged = (value:any) => {
        if((closedDate !== value.target.value) && (closed[value.target.value])) {
            setClosedDate(value.target.value);
            //Close popup
            value.target.confirm(true);
        }
    }

    //console.log("Closed: ", Object.keys(closed));
    // doughnut data for stat
    const chartData = [statistics.hitRatio, 100-statistics.hitRatio];
    const showData = chartData[0] + "%";
    const data1 = {
        labels: ["win", "loss"],
        datasets: [
            {
                data: chartData,
                backgroundColor: ["blue", "orange"]
            }
        ],
        text: showData
    };

    const options1: ChartOptions<"doughnut"> = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    };

    const isDark = document.body.classList.contains('dark');
    const plugins = [{
        beforeDraw: (chart:any) => {
            let width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            let fontSize = (height / 160).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            ctx.fillStyle = isDark ? "#fff" :  "#000";
            let text = Math.round(statistics.hitRatio*100)/100 + "%",
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2.1;
            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    }]

    // end doughnut
    // @ts-ignore
    return (
        <>
            <SideBar/>
            <IonPage id="main-content">

          <AppHeader title={translator.translate('portfolio.title')}/>
          <IonContent fullscreen>
              <IonSegment className={"portfolioSelector"} color="primary" value={view}>
                  <IonSegmentButton key={'open'} value="open" onClick={()=>adjustView('open')}>
                      <IonLabel>{translator.translate('portfolio.open')}</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton key={'closed'} value="closed" onClick={()=>adjustView('closed')}>
                      <IonLabel>{translator.translate('portfolio.closed')}</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton key={'statistics'} value="statistics" onClick={()=>adjustView('statistics')}>
                      <IonLabel>{translator.translate('portfolio.statistics')}</IonLabel>
                  </IonSegmentButton>
              </IonSegment>
              {view === 'open' &&
              <IonAccordionGroup ref={accordionGroup} multiple={true}>
                  {accordionsOpen}
              </IonAccordionGroup>
              }
              {view === 'closed' &&
                <>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle><IonRow><IonCol style={{paddingTop:10}}>{translator.translate('portfolio.tradingDay')}:</IonCol><IonCol><IonDatetimeButton datetime="tradeDay" ></IonDatetimeButton></IonCol></IonRow></IonCardTitle>
                    <IonCardSubtitle>{translator.translate('portfolio.tradingDaySubtitle')}</IonCardSubtitle>
                  </IonCardHeader>

                  <IonCardContent>
                    <IonModal keepContentsMounted={true}>
                    <IonDatetime
                        className={'tradeDay'}
                        id={'tradeDay'}
                        showDefaultTitle={true}
                        locale={i18n.language}

                        min={Object.keys(closed).sort()[0]}
                        max={Object.keys(closed).sort()[Object.keys(closed).length-1]}

                        value={closedDate || Object.keys(closed).sort()[Object.keys(closed).length-1]}
                        //@ts-ignore
                        onClick={(value)=>dateChanged(value)}
                        presentation="date"
                    highlightedDates={(isoString) => {
                        if (isoString in closed) {
                            return {
                                textColor: 'var(--ion-color-secondary-contrast)',
                                backgroundColor: 'var(--ion-color-secondary)',
                            };
                        }
                        return undefined;
                    }}
                  >
                    <span slot="title">{translator.translate('portfolio.calendarHeadline')}</span>
                  </IonDatetime>
                  </IonModal>

                  </IonCardContent>
                </IonCard>
                  <IonAccordionGroup ref={accordionGroup} multiple={true}>
                    {accordionsClose}
                  </IonAccordionGroup>
                  </>
              }
              {view === 'statistics' &&
                  <IonCard>

                    <IonCardHeader>
                      <IonCardTitle>{translator.translate('portfolio.statisticTitle')}</IonCardTitle>
                      <IonCardSubtitle>{translator.translate('portfolio.statisticSubtitle')}</IonCardSubtitle>
                    </IonCardHeader>


                    <IonCardContent>
                      <IonGrid>
                        <IonRow>
                                <IonCol size="9"><h1 style={{paddingTop:"4vh"}}>{translator.translate('portfolio.statistic.hitRatio')}</h1></IonCol>
                            {/*
                            // @ts-ignore*/}
                                <IonCol size="3" style={{height: "15vh", position:"relative", float:'right'}}><Doughnut data={data1} options={options1} plugins={plugins}/></IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="8">{translator.translate('portfolio.statistic.plRatio')}</IonCol>
                          <IonCol size="4" style={{fontWeight:'bold'}}>1 : {Math.round(statistics.plRatio*100)/100}</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="8">{translator.translate('portfolio.statistic.totalReturn')}</IonCol>
                          <IonCol size="4" style={{fontWeight:'bold'}} className={statistics.totalReturn < 0 ? 'negative' : 'positive'}>{Math.round(statistics.totalReturn*100)/100} €</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="8">{translator.translate('portfolio.statistic.totalTrades')}</IonCol>
                          <IonCol size="4" style={{fontWeight:'bold'}}>{Math.round(statistics.totalTrades*100)/100}</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="8">{translator.translate('portfolio.statistic.totalContracts')}</IonCol>
                          <IonCol size="4" style={{fontWeight:'bold'}}>{Math.round(statistics.totalContracts*100)/100}</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="8">{translator.translate('portfolio.statistic.bestTrade')}</IonCol>
                          <IonCol size="4" style={{fontWeight:'bold'}} className={statistics.bestTrade < 0 ? 'negative' : 'positive'}>{Math.round(statistics.bestTrade*100)/100} €</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="8">{translator.translate('portfolio.statistic.worstTrade')}</IonCol>
                          <IonCol size="4" style={{fontWeight:'bold'}} className={statistics.worstTrade < 0 ? 'negative' : 'positive'}>{Math.round(statistics.worstTrade*100)/100} €</IonCol>
                        </IonRow>

                          <IonRow>
                          <IonCol size="8">{translator.translate('portfolio.statistic.profitTrades')}</IonCol>
                          <IonCol size="4" style={{fontWeight:'bold'}}>{Math.round(statistics.profitTrades*100)/100}</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="8">{translator.translate('portfolio.statistic.lossTrades')}</IonCol>
                          <IonCol size="4" style={{fontWeight:'bold'}}>{Math.round(statistics.lossTrades*100)/100}</IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol size="8">{translator.translate('portfolio.statistic.sumProfit')}</IonCol>
                          <IonCol size="4" style={{fontWeight:'bold'}} className={statistics.sumProfit < 0 ? 'negative' : 'positive'}>{Math.round(statistics.sumProfit*100)/100} €</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="8">{translator.translate('portfolio.statistic.sumLoss')}</IonCol>
                          <IonCol size="4" style={{fontWeight:'bold'}} className={statistics.sumLoss < 0 ? 'negative' : 'positive'} >{Math.round(statistics.sumLoss*100)/100} €</IonCol>
                        </IonRow>

                      </IonGrid>

                    </IonCardContent>
                  </IonCard>
              }


        </IonContent>
          <IonModal className={'trade-details-modal'} isOpen={showModal} />
      </IonPage>
            </>
  );
};

export default Portfolio;
