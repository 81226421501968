import "./Navigation.css";
import React from 'react';
import {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    useIonRouter,
    IonButtons, IonMenuButton, IonBadge
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { Route } from 'react-router';

import {wallet, chatbubble, home, barChart, logInOutline, contractOutline, menu} from 'ionicons/icons';
import {useAuth0} from "@auth0/auth0-react";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import {Switch} from "react-router-dom";
import Overview from "../pages/Overview";
import Invoice from "../pages/Invoice";
import Portfolio from "../pages/Portfolio";
import Profile from "../pages/Profile";
import Download from "../pages/Download";
import FaqPage from "../pages/FaqPage";
import Tutorial from "../pages/Tutorial";
import Loader from "./Loader";
import useTranslator from "./useTranslator";
import {Browser} from "@capacitor/browser";
import Imprint from "../pages/Imprint";
import { menuController } from '@ionic/core/components';
import DataProtection from "../pages/DataProtection";

const Navigation: React.FC = () => {
  const { user, isLoading, loginWithRedirect, isAuthenticated } = useAuth0();
  const translator = useTranslator();
  const navigation = useIonRouter();
    const login = async () => {
      await loginWithRedirect({
      async openUrl(url) {
        await Browser.open({
          url,
          windowName: "_self"
        });
      }
    });
    };

    const openSideBar = async () => {
        await menuController.toggle('sidebar-menu');
    }

    const handleTabWillChange = async (e:any) => {
        if (e.detail.tab === 'login') {
            await login();
            navigation.push('/', 'root', 'replace');
        }
        if (e.detail.tab === 'menu') {
            await openSideBar();
        }
    };


    if (isLoading) {
        //return <Loader/>;
        return null;
    }


  //if (!user) return null;

  return (
      <IonReactRouter>
          <IonTabs onIonTabsWillChange={handleTabWillChange}>
              <IonRouterOutlet>

                {/*
                Use the render method to reduce the number of renders your component will have due to a route change.

                Use the component prop when your component depends on the RouterComponentProps passed in automatically.
                */}
                  {/*
                  <Switch>
                      <Route path="/" exact component={Home} />
                      <Route path="/home" exact component={Home} />
                      <Route path="/contact" exact component={Contact} />
                  </Switch>
                  */}
                  <Switch>
                      <Route path="/app/contact" render={() => <Contact />} exact={true} />
                      <Route path="/app/download" render={() => <Download />} exact={true} />
                      <Route path="/app/faq" render={() => <FaqPage />} exact={true} />
                      <Route path="/app/tutorial" render={() => <Tutorial />} exact={true} />
                      <Route path="/app/imprint" render={() => <Imprint />} exact={true} />
                      <Route path="/app/dataProtection" render={() => <DataProtection />} exact={true} />
                      {isAuthenticated ? <Route path="/" render={() => <Overview />} exact={true} /> : <Route path="/" render={() => <Home />} exact={false} />}
                      {isAuthenticated ? <Route path="/app/home" render={() => <Overview />} exact={true} /> : <Route path="/app/home" render={() => <Home />} exact={true} />}
                      <Route path="/app/portfolio" render={() => <Portfolio />} exact={true} />
                      <Route path="/app/invoice" render={() => <Invoice />} exact={true} />
                      <Route path="/app/overview" render={() => <Overview />} exact={true} />
                      <Route path="/app/profile" render={() => <Profile />} exact={true} />
                  </Switch>
              </IonRouterOutlet>

              <IonTabBar slot="bottom" className={"bottomBar"}>
                  <IonTabButton tab={!isAuthenticated ? "home" : "overview"} href={!user ? "/app/home" : "/app/overview"}>
                      <IonIcon icon={!isAuthenticated ? home : contractOutline} />
                      <IonLabel>{!isAuthenticated ? translator.translate('navBar.home') : translator.translate('navBar.overview')}</IonLabel>
                  </IonTabButton>

                  {isAuthenticated &&
                  <IonTabButton tab="portfolio" href="/app/portfolio">
                      <IonIcon icon={barChart} />
                      <IonLabel>{translator.translate('navBar.portfolio')}</IonLabel>
                  </IonTabButton>
                  }
                  {isAuthenticated &&
                  <IonTabButton tab="invoice" href="/app/invoice">
                      <IonIcon icon={wallet} />
                      <IonLabel>{translator.translate('navBar.invoice')}</IonLabel>
                  </IonTabButton>}

                  {!isAuthenticated && <IonTabButton tab={'login'}>
                      <IonIcon icon={logInOutline} />
                      <IonLabel>{translator.translate('navBar.login')}</IonLabel>
                  </IonTabButton>}

                  {/*}
                  <IonTabButton tab="contact" href="/app/contact">
                      <IonIcon icon={chatbubble} />
                      <IonLabel>{translator.translate('navBar.contact')}</IonLabel>
                  </IonTabButton>  */}
                  <IonTabButton tab={'menu'}>
                      <IonIcon icon={menu} />
                      <IonLabel>{translator.translate('navBar.menu')}</IonLabel>
                      {/*<IonBadge color="dark" >2</IonBadge>*/}
                  </IonTabButton>

                  {/*user &&
                  <IonTabButton tab="more" href="/app/overview">
                      <IonIcon icon={menu} />
                      <IonLabel>{translator.translate('navBar.more')}</IonLabel>
                  </IonTabButton>*/}

              </IonTabBar>
          </IonTabs>
      </IonReactRouter>
  );
};

export default Navigation;
