import React from 'react';
import {
    IonButtons,
    IonButton,
    IonContent,
    IonToolbar,
    IonTitle,
    IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonCol, IonRow, IonGrid, IonIcon,
} from '@ionic/react';

import './TradeDetails.scss';
import i18n from "../i18n";
import {arrowDown, arrowUp} from "ionicons/icons";
import useTranslator from "./useTranslator";
import {getTradeByIdAndType} from "./Utils";

const TradeDetails: React.FC<{
    onDismiss: () => void;
    trade: { followerPositionId: string; instrument: string, timestamp: string, followerTradeDirection: string, followerLotSize: string, followerPrice: string };
    view: string;
    openSignals: [];
    closeSignals: [];
    closed: [];
}> = ({ onDismiss, trade ,view,openSignals,closeSignals,closed}) => {
    const translator = useTranslator();

    const plainInstrument = trade.instrument.replace("[","").replace("]","");
    let currency = translator.translate("instruments." + plainInstrument + ".currencySign") !== "instruments." + plainInstrument + ".currencySign" ? translator.translate("instruments." + plainInstrument + ".currencySign") : "";




    const openTransaction = getTradeByIdAndType(closed, trade.followerPositionId, 'Open') || {};
    const closeTransaction = getTradeByIdAndType(closed, trade.followerPositionId, 'Close') || {};

    return (
            <IonContent>
                <IonToolbar>
                    <IonTitle>Trade Id: #{trade.followerPositionId}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="light" onClick={() => onDismiss()}>
                            {translator.translate('portfolio.close')}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>{translator.translate('portfolio.detailView')}</IonCardTitle>
                        <IonCardSubtitle>{trade.followerTradeDirection} {trade.followerLotSize} {translator.translate('portfolio.lots')} {trade.instrument}</IonCardSubtitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12"></IonCol>
                            </IonRow>

                                {view === 'open' && <IonRow>
                                <IonCol size="6">{translator.translate('portfolio.executionTime')}: </IonCol>
                                <IonCol size="6">{new Date(trade.timestamp).toLocaleString(i18n.language)}</IonCol>
                                </IonRow>}

                                {view === 'closed' && <IonRow>
                                  <IonCol size="6">{translator.translate('portfolio.startTime')}: </IonCol>
                                  <IonCol size="6">{new Date(openTransaction.timestamp).toLocaleString(i18n.language)}</IonCol>
                                </IonRow>}
                                {view === 'closed' && <IonRow>
                                  <IonCol size="6">{translator.translate('portfolio.endTime')}: </IonCol>
                                  <IonCol size="6">{new Date(closeTransaction.timestamp).toLocaleString(i18n.language)}</IonCol>
                                </IonRow>}

                            <IonRow>
                            <IonCol size="6">{translator.translate('portfolio.positionSize')}: </IonCol>
                            <IonCol size="6">{trade.followerTradeDirection === "Sell" ? <IonIcon style={{color:'red'}} icon={arrowDown} /> :
                                <IonIcon style={{color:'lightgreen'}} icon={arrowUp} />} {trade.followerLotSize} {translator.translate("portfolio.lots")}
                            </IonCol>
                            </IonRow>

                            {view === "open" &&
                                <IonRow>
                                <IonCol size="6">{translator.translate('portfolio.executionPrice')}: </IonCol>
                                <IonCol size="6">{currency} {(Math.round(parseFloat(trade.followerPrice)  * 100)/100).toFixed(2)}</IonCol>
                            </IonRow>}

                            {view === "closed" &&
                              <>
                              <IonRow>
                                <IonCol size="6">{translator.translate('portfolio.startPrice')}: </IonCol>
                                <IonCol size="6">{currency} {(Math.round(parseFloat(openTransaction.followerPrice)  * 100)/100).toFixed(2)}</IonCol>
                              </IonRow>
                                <IonRow>
                                <IonCol size="6">{translator.translate('portfolio.endPrice')}: </IonCol>
                                <IonCol size="6">{currency} {(Math.round(parseFloat(closeTransaction.followerPrice)  * 100)/100).toFixed(2)}</IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol size="6">{translator.translate('portfolio.profit')}: </IonCol>
                                  <IonCol size="6">€ {(Math.round(parseFloat(closeTransaction.followerTradeResult)  * 100)/100).toFixed(2)}</IonCol>
                                </IonRow>
                              </>
                            }


                            {view === "open" && <IonRow>
                                <IonCol size="12"><IonButton disabled={true}  onClick={()=>{alert("Ready when you are...:D")}}>{translator.translate('portfolio.closeTrade')}</IonButton></IonCol>
                            </IonRow>}
                        </IonGrid>

                    </IonCardContent>
                </IonCard>
            </IonContent>
    );
}

export default TradeDetails;