import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader, IonCardSubtitle, IonCardTitle,
    IonContent, IonInput,
    IonPage,
    useIonToast
} from "@ionic/react";

import { useAuth0 } from "@auth0/auth0-react";
import "./Contact.scss";
import { AppHeader } from "../components/AppHeader";
import React, {useRef, useState} from "react";
import Loader from "../components/Loader";
import useTranslator from "../components/useTranslator";
import emailjs from 'emailjs-com';
import SideBar from "../components/SideBar";

const Contact: React.FC = () => {
  const { isLoading } = useAuth0();
  const translator = useTranslator();
  const [present] = useIonToast();
  const form = useRef<HTMLFormElement>(null);

    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);


  if (isLoading) {
    return <Loader/>;
  }


    const presentToast = (position: 'top' | 'middle' | 'bottom', message: string, color: 'success' | 'warning' | 'danger', duration = 3000) => {
        present({
            message: message,
            cssClass: 'customToast',
            duration: duration,
            position: position,
            color: color,
        });
    };

    const sendEmail = (e: any,translator:any) => {
        e.preventDefault();
        setIsSendButtonDisabled(true);

        emailjs.sendForm('service_2yg8hkx', 'template_k2jfwhg', form.current!, 'iVbQmVN_DbKHOujgN')
            .then((result:any) => {
                //result.text!
                presentToast('top' ,translator.translate('contact.successSendingMail'), 'success');
                form.current!.reset();
            }, (error:any) => {
                console.log("ERROR sending mail: ", error.text!);
                presentToast('top' ,translator.translate('contact.errorSendingMail'), 'warning', 7000);
            });

        setTimeout(() => {setIsSendButtonDisabled(false)}, 5000);
    };


  return (
      <>
          <SideBar/>
          <IonPage id="main-content">
        <AppHeader title={"Contact"}/>
        <IonContent fullscreen>


            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>{translator.translate('contact.formHeadline')}</IonCardTitle>
                    <IonCardSubtitle>{translator.translate('contact.formSubline')}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                    <form className='cf' ref={form} onSubmit={(e) => sendEmail(e, translator)}>
                        <div className='half left cf'>
                            <input type='text' placeholder={translator.translate('contact.formName')} name='user_name' />
                            <input type='email' placeholder={translator.translate('contact.formEmail')} name='user_email' />
                        </div>
                        <div className='half right cf'>
                            <textarea name='message' placeholder={translator.translate('contact.formMessage')}></textarea>
                        </div>
                        <IonButton disabled={isSendButtonDisabled} type='submit' id='input-submit' style={{marginTop: "1.5rem", width:"100%", height: "3rem"}}>{translator.translate('contact.formSubmit')}</IonButton>
                        {/*<input type='submit' value={translator.translate('contact.formSubmit')} id='input-submit' />*/}
                    </form>
                </IonCardContent>
            </IonCard>
        </IonContent>
      </IonPage>
          </>
  );
};

export default Contact;
