import React, {useState} from "react"
import {IonApp, IonRouterOutlet, IonToast, setupIonicReact} from "@ionic/react";
import { App as CapApp } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { useAuth0 } from "@auth0/auth0-react";
import { callbackUri } from "./auth.config";
import "./App.scss";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import { useEffect } from "react";
import Navigation from "./components/Navigation";
import {IonReactRouter} from "@ionic/react-router";
import {Route} from "react-router";
import i18n from "./i18n";
import {I18nextProvider} from "react-i18next";
import { withTranslation } from 'react-i18next';
import {getMyToken, onMessageListener} from "./firebase";



setupIonicReact({
  mode: "md",
});

const defaultGlobalState = {
  accountNumber: "",
  candleChart: false,
};

export const GlobalStateContext = React.createContext(defaultGlobalState);
//@ts-ignore
export const DispatchStateContext = React.createContext<React.Dispatch<any> | undefined>(undefined);



//@ts-ignore
const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
      (state:any, newValue:any) => ({ ...state, ...newValue }),
      defaultGlobalState
  );
  return (
      //@ts-ignore
      <GlobalStateContext.Provider value={state}>
        <DispatchStateContext.Provider value={dispatch}>
          {children}
        </DispatchStateContext.Provider>
      </GlobalStateContext.Provider>
  );
};


const App: React.FC = () => {
  const { handleRedirectCallback } = useAuth0();

  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  getMyToken(setTokenFound);

  onMessageListener().then(payload => {
    setShow(true);
    //@ts-ignore
    setNotification({title: payload.notification.title, body: payload.notification.body})
    console.log(payload);
    //@ts-ignore
  }).catch(err => console.log('failed: ', err));


  useEffect(() => {
    CapApp.addListener("appUrlOpen", async ({ url }) => {
      // @ts-ignore
      if (url.startsWith(callbackUri)) {
        if (
          url.includes("state") &&
          (url.includes("code") || url.includes("error"))
        ) {
          await handleRedirectCallback(url);
        }

        await Browser.close();
      }
    });
  }, [handleRedirectCallback]);

  return (
      <GlobalStateProvider>
        <I18nextProvider i18n={i18n} defaultNS={'translation'}>
          <IonApp>
            {isTokenFound && <h1 style={{display: "none"}}> Notification permission enabled 👍🏻 </h1>}
            {!isTokenFound && <h1 style={{display: "none"}}> Need notification permission ❗️ </h1>}

            <IonToast
                isOpen={show}
                position={"middle"}
                message={notification.body}
                onDidDismiss={() => setShow(false)}
                cssClass={"custom-toast"}
                duration={5000}
            ></IonToast>

            {/*
            <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
              position: 'absolute',
              top: 20,
              right: 20,
              minWidth: 200
            }}>
              <Toast.Header>

                <img
                    src="holder.js/20x20?text=%20"
                    className="rounded mr-2"
                    alt=""
                />
                <strong className="mr-auto">{notification.title}</strong>
                <small>just now</small>
              </Toast.Header>
              <Toast.Body>{notification.body}</Toast.Body>
            </Toast>*/}

            <IonReactRouter>
              <IonRouterOutlet>
                <Route exact path={"/"} component={Navigation}/>
                <Route path={"/app"} component={Navigation}/>
              </IonRouterOutlet>
            </IonReactRouter>
          </IonApp>
        </I18nextProvider>
      </GlobalStateProvider>
  );
};

export default withTranslation()(App);
