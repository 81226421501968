import {useAuth0} from "@auth0/auth0-react";
import {audience, clientId, domain} from "../auth.config";
import {Browser} from "@capacitor/browser";
import i18n from "../i18n";
import useTranslator from "./useTranslator";

export const getConfig = () => {
    const configJson = {
        "domain": domain,
        "clientId": clientId,
        "audience": audience
    }
    // Configure the audience here. By default, it will take whatever is in the config
    // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
    // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
    // don't have an API).
    // If this resolves to `null`, the API page changes to show some helpful info about what to do
    // with the audience.
    const my_audience =
        configJson.audience && configJson.audience !== "YOUR_API_IDENTIFIER"
            ? audience
            : null;

    return {
        domain: configJson.domain,
        clientId: configJson.clientId,
        ...(my_audience ? { audience: my_audience } : null)
    };
}

export const GetCandleChartOptions = () => {
    const translator = useTranslator();

    return {
        chart: {
            id: 'chart',
            type: 'candlestick' as const,
            animations: {enabled: true,
                initialAnimation: {
                    enabled: false
                }},
            stacked:true,
            toolbar: {
                show: false,
                autoSelected: 'pan' as const,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    /*reset:  false || '<img src="/static/icons/reset.png" width="20">',*/
                    customIcons: []
                },
            },
            zoom: {
                type: 'x' as const,
                enabled: true,
                autoScaleYaxis: true
            },
            selection: {enabled: false},
            zoomX: {}
        },
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#3880ff',
                    downward: '#f4623a'
                },
            }
        },
        xaxis: {
            type: "datetime" as const,
            tickPlacement: 'on',
            lines: {
                show: false
            },
            axisBorder: {
                show: true,
                color: '#191919'
            },
            labels: {
                show: true,
                hideOverlappingLabels: true,
                showDuplicates: false,
                formatter: (value:any, timestamp:any, opts:any) => {
                    return new Date(timestamp).toLocaleDateString(i18n.language,{ day: 'numeric', month: 'short' });
                },
                style: {
                    colors: [],
                    fontSize: '12px',
                    fontWeight: 400,
                    cssClass: 'chart-label',
                    padding: '4px',
                },
            },
            range: undefined,
            min: 0,
            max: 0
        },
        yaxis: {
            min: 0,
            forceNiceScale: false,
            tickAmount: 4
        },
        grid:{
            xaxis: {
                type: 'datetime',
                lines: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: '#191919'
                }
            },
            yaxis: {
                lines: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: '#191919'
                }
            },
            borderColor: '#191919'
        },

        tooltip: {
            shared: true,
            //@ts-ignore
            custom: [({seriesIndex, dataPointIndex, w}) => {
                const title = w.globals.series[seriesIndex][dataPointIndex] >=0 ? translator.translate("overview.overallProfit") : translator.translate("overview.overallLoss");
                const dailyTitle = w.globals.seriesCandleC[seriesIndex][dataPointIndex] - w.globals.seriesCandleO[seriesIndex][dataPointIndex] >= 0 ? translator.translate("overview.dailyProfit") : translator.translate("overview.dailyLoss");

                return '<div class="arrow_box">' +
                    '<h3>' + translator.translate("overview.candleChartTooltipTitle") + '</h3>' +
                    '<div>' + dailyTitle + ': <strong>€ ' + (w.globals.seriesCandleC[seriesIndex][dataPointIndex] - w.globals.seriesCandleO[seriesIndex][dataPointIndex]).toFixed(2) + '</strong></div>' +
                    '<div>' + title + ': <strong>€ ' + w.globals.series[seriesIndex][dataPointIndex]  + '</strong></div>' +
                    '</div>';
            }]
        }


    };

}


export const GetCandleTimeLineOptions = () => {
    const translator = useTranslator();

    return {
        chart: {
            height: 120,
            type: 'bar' as const,
            toolbar: {
                autoSelected: 'selection' as const,
            },
            brush: {
                enabled: true,
                target: 'chart' as const
            },
            selection: {
                enabled: true,
                xaxis: {
                    min: new Date('16 Jan 2023').getTime(),
                    max: new Date('17 Jun 2023').getTime()
                },
                fill: {
                    color: '#c0c0c0',
                    opacity: 0.1
                },
            },
            zoom: {
                autoScaleYaxis: true
            }
        },
        plotOptions: {
            bar: {
                colors: {
                    ranges: [{
                        from: -9999999,
                        to: 0,
                        color: '#f4623a'
                    }, {
                        from: 0,
                        to: 9999999,
                        color: '#3880ff'
                    }]
                },
                columnWidth: '80%',
            }
        },
        colors: [
            '#9F9F9F'
        ],
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 0,
            curve: 'smooth' as const
        },
        fill: {
            opacity: 1,
            type: 'solid'
        },
        xaxis: {
            type: 'datetime' as const,
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: true,
                showDuplicates: false,
                formatter: (value:any, timestamp:any, opts:any) => {
                    return new Date(timestamp).toLocaleDateString(i18n.language);
                },
                style: {
                    colors: [],
                    fontSize: '12px',
                    fontWeight: 400,
                    cssClass: 'chart-label',
                    padding: '4px',
                },
            },
            categories: [] as any,
            tickAmount: 3,
            axisBorder: {
                show: true,
                color: '#191919'
            },
        },
        yaxis: {
            labels: {
                show: true,
            },
            tickAmount: 2,
            /*
            forceNiceScale: false,
            min:0,
            max:0,
            */
        },
        grid: {
            show: false,
            padding: {
                left: 56,
                right: 0
            }
        },
        tooltip: {
            enabled: false
        }
    }
}


export const getTradeByIdAndType = (source: any[] = [], id = "", dealType="") => {
    for (let trades in source ) {
        for (let trade in source[trades] ) {
            if (source[trades][trade].followerPositionId === id) {
                if (source[trades][trade].followerDealType === dealType)
                    return source[trades][trade];
            }
        }
    }
    return {};
}

export const Login = async () => {
    const { loginWithRedirect } = useAuth0();
    await loginWithRedirect({
      async openUrl(url) {
        await Browser.open({
          url,
          windowName: "_self"
        });
      }
    });
};

interface StaticFields {
    accessToken: string,
    readApi: (url:string) => {
        /*
        showResult: boolean;
        responseData?: object;
        */
    },
}

//@ts-ignore
export const Utils: FC<Props> & StaticFields = (props) => {
    const { getAccessTokenSilently } = useAuth0();
}



Utils.readApi = async (url:string): Promise<{ showResult: boolean, responseData?: object }> => {
    let res = {showResult:false,responseData:{}}

    //@ts-ignore
    const { apiOrigin = "https://client.metatrader-panel.com/api/v1", audience } = getConfig();

    try {
        const token = Utils.accessToken;
        const response = await fetch(`${apiOrigin}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        res.responseData = await response.json();
        res.showResult = true;
    } catch (error:any) {
        if (error.error === 'missing_refresh_token' || error.error === 'invalid_grant') {
            //await loginWithRedirect();
        }
        res.showResult = false;
        console.error("ERROR: ", error);
    }

    return res;
}

//const getLanguage = () => i18next.language || window.localStorage.i18nextLng