import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import useTranslator from "./useTranslator";
import {Link} from "react-router-dom";


const CookieBanner: React.FC<{}> = () => {
    const translator = useTranslator();
    return <CookieConsent
        location="bottom"
        buttonText={translator.translate("component.cookieBanner.cookieAcceptButton")}
        cookieName="cookiesAccepted"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        overlay={((window.location.pathname.indexOf("dataProtection") !== -1) || (window.location.pathname.indexOf("imprint") !== -1)) ? false : true}
    >
        {translator.translate("component.cookieBanner.description")}
        <p style={{ fontWeight: "bold" }}>{translator.translate("component.cookieBanner.descriptionAddon")} <Link to="/app/dataProtection#cookies" >{translator.translate("component.cookieBanner.privacyPolicy")}</Link>.</p>
    </CookieConsent>
}

export default CookieBanner;