import { useAuth0 } from "@auth0/auth0-react";
import {
    IonAvatar,
    IonBackButton,
    IonButtons,
    IonContent, IonItem, IonLabel,
    IonPage, IonSelect, IonSelectOption,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React from "react";
import LogoutButton from "../components/LogoutButton";
import i18n from "../i18n";
import useTranslator from "../components/useTranslator";
import SideBar from "../components/SideBar";

const Profile: React.FC = () => {
    const { isLoading, user } = useAuth0();
    const translator = useTranslator();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!user)
      return null;

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    }

  return (
      <>
          <SideBar/>
          <IonPage id="main-content">
          {/*<AppHeader title={"Contact"}/>*/}
          <IonToolbar className={"headerToolbar"}>
              <IonButtons slot={"start"}>
                  <IonBackButton defaultHref={'/app'}></IonBackButton>
              </IonButtons>
              <IonTitle>{translator.translate('profile.title')}</IonTitle>
          </IonToolbar>
          <IonContent fullscreen>
              <div className={'container'}>
                  <h1>{translator.translate('profile.headline')}</h1>

                  <div className="profile-container">
                      <IonAvatar className="avatar">
                          <img src={user.picture} alt={user.name} />
                      </IonAvatar>
                      <h2>{user.name}</h2>
                      <p>{user.email}</p>
                      <LogoutButton/>
                  </div>

                  <h2 style={{paddingTop: 24, textAlign:"left"}}>{translator.translate('profile.settingsSection')}</h2>


                      <IonItem>
                          <IonLabel>{translator.translate('profile.language')}</IonLabel>
                          <IonSelect
                              aria-label="Sprache"
                              interface="action-sheet"
                              placeholder="Select language"
                              cancelText={translator.translate('profile.settings.cancelChoice')}
                              value={i18n.language}
                              onIonChange={e => changeLanguage(e.detail.value)}
                          >
                              <IonSelectOption value="en">{translator.translate('profile.settings.english')}</IonSelectOption>
                              <IonSelectOption value="de">{translator.translate('profile.settings.german')}</IonSelectOption>
                          </IonSelect>
                      </IonItem>

              </div>


          </IonContent>

      </IonPage>
          </>
  );
};

export default Profile;
