import useTranslator from "../components/useTranslator";
import {
    IonButton,
    IonCard, IonCardContent,
    IonCardHeader, IonCardSubtitle, IonCardTitle,
    IonContent, IonHeader, IonMenu,
    IonPage, IonTitle, IonToolbar, useIonRouter, useIonToast
} from "@ionic/react";

import { useAuth0 } from "@auth0/auth0-react";
import "./Home.scss";

import React from "react";
import { AppHeader } from "../components/AppHeader";
import {Redirect} from "react-router";

import { useHistory } from "react-router-dom";
import SideBar from "../components/SideBar";

const Home: React.FC = () => {

  const { isLoading, user, isAuthenticated } = useAuth0();
  const history = useHistory();
  const translator = useTranslator();
  const navigation = useIonRouter();
  const [present] = useIonToast();
  if (isLoading) {
    return null;
  }

  if(isAuthenticated) {
      //history.push("/app/overview");
      navigation.push('/app/overview', 'root', 'replace');
  }

    const presentToast = (position: 'top' | 'middle' | 'bottom', message: string, color: 'success' | 'warning' | 'danger', duration = 3000) => {
        present({
            message: message,
            cssClass: 'customToast',
            duration: duration,
            position: position,
            color: color,
        });
    };

    //@ts-ignore
    let deferredPrompt = null;
    window.addEventListener('beforeinstallprompt', (e) => {
        deferredPrompt = e;
    });

    const installApp = async() => {
        //@ts-ignore
        if (deferredPrompt !== null) {
            //@ts-ignore
            deferredPrompt.prompt();
            //@ts-ignore
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                deferredPrompt = null;
            }
        } else {
            presentToast('top' ,translator.translate('home.errorInstallApp'), 'warning', 7000);
            console.log("deferred prompt is null [Website cannot be installed]")
        }
    }


  return (
      <>
      <SideBar/>
        <IonPage id="main-content">
      <AppHeader/>
      <IonContent fullscreen>
          <h1 style={{paddingLeft: 12}}>{translator.translate('home.welcome')}</h1>
          <IonCard>
            <img alt="Silhouette of mountains" src="../assets/invite.avif" width={"100%"} height={"100%"} />
            <IonCardHeader>
              <IonCardTitle>{translator.translate('home.teaserTitle1')}</IonCardTitle>
              <IonCardSubtitle>{translator.translate('home.teaserSubTitle1')}</IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>{translator.translate('home.teaserText1')}</IonCardContent>
          </IonCard>
          <IonCard>
              <img alt="Silhouette of mountains" src="../assets/request.avif" width={"100%"} height={"100%"} />
              <IonCardHeader>
                  <IonCardTitle>{translator.translate('home.teaserTitle2')}</IonCardTitle>
                  <IonCardSubtitle>{translator.translate('home.teaserSubTitle2')}</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>{translator.translate('home.teaserText2')}</IonCardContent>
          </IonCard>

          <IonCard className={"pwaInstallation"}>
              <IonCardHeader>
                  <IonCardTitle>{translator.translate('home.installTitle')}</IonCardTitle>
                  <IonCardSubtitle>{translator.translate('home.installSubtitle')}</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent><IonButton onClick={()=>installApp()}>{translator.translate('home.installButton')}</IonButton></IonCardContent>
          </IonCard>

      </IonContent>
    </IonPage>
          </>
  );
};

export default Home;
