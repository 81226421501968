import React, {useEffect, useLayoutEffect, useState} from "react";
import {
    IonAvatar, IonChip,
    IonContent, IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu, IonMenuToggle, IonNote, IonSelect, IonSelectOption, IonTabButton,
    IonTitle, IonToggle,
    IonToolbar, useIonRouter
} from "@ionic/react";
import {
    chatbubbleOutline,
    chatbubblesOutline,
    cloudDownloadOutline, cogOutline,
    constructOutline, copyOutline, help, helpCircle, helpCircleOutline, information, informationCircleOutline,
    libraryOutline, logInOutline, personCircleOutline,
    walletOutline,
} from "ionicons/icons";
import type { ToggleCustomEvent } from '@ionic/react';
import {useAuth0} from "@auth0/auth0-react";
import useTranslator from "./useTranslator";
import {Browser} from "@capacitor/browser";
import {callbackUri} from "../auth.config";
import i18n from "../i18n";
import {Link} from "react-router-dom";

const SideBar: React.FC = () => {
    const { user, isAuthenticated,loginWithRedirect, logout } = useAuth0();
    const translator = useTranslator();
    const navigation = useIonRouter();
    const [themeToggle, setThemeToggle] = useState(false);

    const login = async () => {
        await loginWithRedirect({
            async openUrl(url) {
                await Browser.open({
                    url,
                    windowName: "_self"
                });
            }
        });
    };

    const doLogout = async () => {
        await logout({
            async openUrl(url) {
                await Browser.open({
                    url,
                    windowName: "_self",
                });
            },
            logoutParams: {
                returnTo: callbackUri
            }
        });
        navigation.push('/app/home', 'root', 'replace');
    };

    // Listen for the toggle check/uncheck to toggle the dark theme
    const toggleChange = (ev: ToggleCustomEvent) => {
        toggleDarkTheme(ev.detail.checked);
        //localStorage.setItem("theme", ev.detail.checked ? 'dark' : 'light');
    };

    // Add or remove the "dark" class on the document body
    const toggleDarkTheme = (shouldAdd: boolean) => {
        document.body.classList.toggle('dark', shouldAdd);
    };

    // Check/uncheck the toggle and update the theme based on isDark
    const initializeDarkTheme = (isDark: boolean) => {
        //let userSetting = "";
        //if (window.localStorage?.theme) {
        //    userSetting = window.localStorage?.theme.toString();
        //}

        //if(userSetting === "dark" || (userSetting !== "light" && isDark) ) {
            setThemeToggle(isDark);
            toggleDarkTheme(isDark);
        //}
    };

    useEffect(() => {
        // Use matchMedia to check the user preference
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

        // Initialize the dark theme based on the initial
        // value of the prefers-color-scheme media query
        initializeDarkTheme(prefersDark.matches);

        // Listen for changes to the prefers-color-scheme media query
        prefersDark.addEventListener('change', (mediaQuery) => initializeDarkTheme(mediaQuery.matches));
    }, []);


    useLayoutEffect(() => {
        {/*
        if(window.localStorage.theme.toString() === "dark") {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
        */}
    }, []);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    }

    return <IonMenu menuId="sidebar-menu"  contentId="main-content">
        <IonHeader>
            <IonToolbar color="tertiary">
                <IonTitle>{translator.translate('component.sideBar.mainmenu')}</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
            <IonList className={"sideBarContent"} >
                {/*
                 <IonListHeader>
                     {translator.translate('component.sideBar.toolbox')}
                 </IonListHeader>
                    */}

                    <IonItem routerLink="/app/faq">
                            <IonIcon aria-hidden="true" icon={help} slot="start"></IonIcon>
                            <IonLabel>{translator.translate('component.sideBar.help')}</IonLabel>
                    </IonItem>

                    <IonItem routerLink="/app/download">
                        <IonIcon aria-hidden="true" icon={cloudDownloadOutline} slot="start"></IonIcon>
                        <IonLabel>{translator.translate('component.sideBar.download')}</IonLabel>
                    </IonItem>

                    <IonItem routerLink="/app/tutorial" disabled={true}>
                        <IonIcon aria-hidden="true" icon={constructOutline} slot="start"></IonIcon>
                        <IonLabel>{translator.translate('component.sideBar.tutorial')}</IonLabel>
                    </IonItem>

                {/*
                <IonListHeader>
                    {translator.translate('component.sideBar.various')}
                </IonListHeader>
                */}
                <IonItem routerLink="/app/contact">
                    <IonIcon aria-hidden="true" icon={chatbubbleOutline} slot="start"></IonIcon>
                    <IonLabel>{translator.translate('component.sideBar.contact')}</IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>{translator.translate('profile.language')}</IonLabel>
                    <IonSelect
                        aria-label="Sprache"
                        interface="action-sheet"
                        placeholder="Select language"
                        cancelText={translator.translate('profile.settings.cancelChoice')}
                        value={i18n.language}
                        onIonChange={e => changeLanguage(e.detail.value)}
                    >
                        <IonSelectOption value="en">{translator.translate('profile.settings.english')}</IonSelectOption>
                        <IonSelectOption value="de">{translator.translate('profile.settings.german')}</IonSelectOption>
                    </IonSelect>
                </IonItem>

                {/*
                <IonItem routerLink="/app/imprint">
                    <IonIcon aria-hidden="true" icon={information} slot="start"></IonIcon>
                    <IonLabel>{translator.translate('component.sideBar.imprint')}</IonLabel>
                </IonItem>
                */}
                {/*
                <IonItem>
                    <IonToggle>
                    </IonToggle>
                    <IonLabel color={"white"}>Nachrichten erlauben</IonLabel>
                </IonItem>
                */}




            </IonList>



                    <IonList className={"sideBarContent"} style={{paddingTop:"2rem"}}>
                <IonListHeader>
                    {translator.translate('component.sideBar.account')} {user && <div style={{marginLeft:".5rem"}}> <IonChip color="primary">{user.name}</IonChip></div>}
                </IonListHeader>
                {isAuthenticated ?
                <>
                <IonItem disabled={true}>
                    <IonIcon aria-hidden="true" icon={walletOutline} slot="start"></IonIcon>
                    <IonLabel>{translator.translate('component.sideBar.payment')}</IonLabel>
                </IonItem>
                <IonItem disabled={true}>
                    <IonIcon aria-hidden="true" icon={cogOutline} slot="start"></IonIcon>
                    <IonLabel>{translator.translate('component.sideBar.settings')}</IonLabel>
                </IonItem>
                <IonItem className={"clickTarget"} onClick={async()=>doLogout()}>
                    <IonAvatar style={{height:24,width:24}}>
                        <img  src={user!.picture} alt={user!.name} />
                    </IonAvatar>
                    {/*<IonIcon aria-hidden="true" icon={personCircleOutline} slot="start"></IonIcon>*/}
                    <IonLabel style={{marginLeft: 30}}>{translator.translate('component.sideBar.logout')}</IonLabel>
                </IonItem></> :
                            <IonItem className={"clickTarget"} onClick={async()=>{await login(); navigation.push('/', 'root', 'replace')}}>
                            <IonIcon aria-hidden="true" icon={logInOutline} slot="start"></IonIcon>
                            <IonLabel>{translator.translate('component.sideBar.login')}</IonLabel>
                            </IonItem>
                        }

            </IonList>
        </IonContent>
        <IonFooter style={{backgroundColor: "var(--ion-color-tertiary)"}}>
            <div style={{fontSize:"1rem", padding:"1rem", paddingBottom:".25rem", color: "var(--ion-color-tertiary-contrast)"}}>
                <Link style={{color:"var(--ion-color-text)"}} to="/app/imprint">{translator.translate('component.sideBar.imprint')}</Link> | <Link style={{color:"var(--ion-color-text)"}} to="/app/dataProtection">{translator.translate('component.sideBar.dataProtection')}</Link>
            </div>
            <div style={{fontSize:".825rem",fontWeight: "bold", padding:"1rem", paddingTop:".25rem"}}>
                © 2023 FollowMe - Version: {process.env.REACT_APP_VERSION}
            </div>
        </IonFooter>

    </IonMenu>
}

export default SideBar;