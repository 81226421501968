import {
    IonCard, IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonPage,
} from "@ionic/react";

import { useAuth0 } from "@auth0/auth0-react";
import { AppHeader } from "../components/AppHeader";
import React from "react";
import Loader from "../components/Loader";
import useTranslator from "../components/useTranslator";
import SideBar from "../components/SideBar";

const Tutorial: React.FC = () => {
  const { isLoading } = useAuth0();
  const translator = useTranslator();

  if (isLoading) {
    return <Loader/>;
  }

  return (
      <>
        <SideBar/>
        <IonPage id="main-content">
            <AppHeader title={"Contact"}/>
            <IonContent fullscreen>
            <div className={'container'} style={{padding: '.5rem'}}>
            <IonCard>
              <IonCardHeader>
                  <img alt={"robot"} src={"../assets/robot.jpg"}/>
                  <IonCardTitle>{translator.translate('tutorial.headline')}</IonCardTitle>
                  <IonCardSubtitle>{translator.translate('tutorial.description')}</IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
            </div>
            </IonContent>
        </IonPage>
          </>
  );
};

export default Tutorial;
